<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">BROADSHEET </span>
    </PageHeader>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-form ref="courseHeader">
              <v-row>
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    :loading="loadProgram"
                    v-model="program"
                    :items="itemsProgram"
                    @keyup="searchProgram"
                    clearable
                    hide-details="auto"
                    hide-selected
                    class="rounded-0"
                    item-text="program_name_certificate"
                    item-value="id"
                    label="Search Program"
                    outlined
                    :rules="[(v) => !!v || 'Program is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="loadProgram">
                          Searching
                          <strong>Program...</strong>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No
                          <strong>Program Found...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :items="basket_campus"
                    item-value="id"
                    multiple
                    item-text="campus_name"
                    @click="fetchCampus"
                    :loading="iscampusLoaded"
                    hide-details="auto"
                    class="rounded-0"
                    outlined
                    label="Campus"
                    v-model="campus"
                    :rules="[(v) => !!v || 'Campus is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Campus...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="iscalendarLoaded"
                    v-model="calendar"
                    :items="basket_calendar"
                    :item-text="
                      (item) =>
                        `${item.academic_calendar} ${
                          item.current ? ' Current' : ''
                        }`
                    "
                    item-value="id"
                    outlined
                    class="rounded-0"
                    label="Calendar"
                    @click="fetchCalendar"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Calendar is required...']"
                  >
                    <template v-slot:selection="{ item }">
                      <span>
                        {{ `${item.academic_calendar}` }}

                        <b>{{ `${item.current ? " Current" : ""}` }} </b>
                      </span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Calendar(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="12" lg="3">
                <v-btn
                  height="45"
                  :disabled="disabled"
                  class="title"
                  block
                  color="primary"
                  @click="downloadBroadsheetBtn"
                >
                  DOWNLOAD EXCEL
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { getCurrentInstance, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Error from "@/components/Error/Error";

  export default {
    components: { PageHeader, Error },
    setup() {
      const vm = getCurrentInstance();
      const { getProgram, getCampus, broadSheet, signOut, getCalendar } =
        useActions([
          "getProgram",
          "getCampus",
          "getCampus",
          "broadSheet",
          "signOut",
          "getCalendar",
        ]);

      const {
        getters_program,
        getters_campus,
        getters_abilities,
        user,
        getters_calendar,
      } = useGetters([
        "getters_program",
        "getters_campus",
        "getters_abilities",
        "getters_calendar",
        "user",
      ]);

      const courseHeader = reactive({
        overlay: false,
        absolute: true,
        get_program_info: {},
        iscampusLoaded: false,
        loadedProgram: true,
        program: "",
        campus: "",
        basket_campus: [],
        itemsProgram: [],
        loadProgram: false,
        disabled: true,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        calendar: "",
        basket_calendar: [],
        iscalendarLoaded: false,
      });

      const {
        overlay,
        absolute,
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
        itemsProgram,
        loadProgram,
        basket_campus,
        program,
        campus,
        iscampusLoaded,
        disabled,
        basket_calendar,
        calendar,
        iscalendarLoaded,
      } = toRefs(courseHeader);

      const dialog = ref(false);
      const assigned = ref(false);
      const header = ref([]);

      watch(
        () => [program.value, campus.value, calendar.value],
        () => {
          if (program.value && campus.value && calendar.value) {
            header.value = {
              program_id: program.value,
              calendar_id: calendar.value,
              campus_id: campus.value,
            };
            disabled.value = false;
          } else {
            disabled.value = true;
          }
        }
      );

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_calendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_calendar.value;
          return;
        }
        getCalendar().then(() => {
          basket_calendar.value = getters_calendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const searchProgram = debounce(() => {
        if (itemsProgram.value.length > 0) return;
        loadProgram.value = true;

        getProgram()
          .then(() => {
            itemsProgram.value = getters_program.value;
          })
          .then(() => (loadProgram.value = false));
      });

      const fetchCampus = () => {
        iscampusLoaded.value = true;
        getCampus().then(() => {
          if (getters_abilities.value.includes("all_campus_access")) {
            basket_campus.value = getters_campus.value;
          } else {
            basket_campus.value = getters_campus.value.filter(
              (campus) => campus.id === parseInt(user.value.campus[0].id)
            );
          }
          iscampusLoaded.value = false;
        });
      };

      const downloadBroadsheetBtn = () => {
        deleteResponse.value = false;
        overlay.value = true;
        broadSheet(header.value)
          .then(() => (overlay.value = false))
          .catch((e) => {
            msgIcon.value = "mdi-close-circle";
            deleteResponse.value = true;
            msgHeader.value = "Error";
            overlay.value = false;
            if (e.response.status === 422) {
              msgBody.value = "Header Information Does Not Exists";
            } else if (e.response.status === 403) {
              msgBody.value = "This Action Is Unauthorized";
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      return {
        ...toRefs(courseHeader),
        fetchCampus,
        absolute,
        overlay,
        searchProgram,
        dialog,
        assigned,
        downloadBroadsheetBtn,
        fetchCalendar,
      };
    },
  };
</script>
<style scoped>
  .border:hover {
    border-left: 5px solid #1976d2 !important;
    cursor: pointer !important;
  }
</style>
